@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import url('../icons/line-awesome/css/line-awesome.min.css');
@import url('../icons/font-awesome/css/font-awesome.min.css');
@import url('../icons/cryptocoins/css/cryptocoins.css');
@import url('../icons/cryptocoins/css/cryptocoins-colors.css');
@import url('../icons/material-design-iconic-font/css/materialdesignicons.min.css');
@import url('../icons/flag-icon-css/flag-icon.min.css');
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1652f0;
  --secondary: #673bb7;
  --success: #10d876;
  --info: #2754e6;
  --warning: #fe9431;
  --danger: #e50202;
  --light: #f8f9fa;
  --dark: #131722;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --card-background: #131722;
  --base-background: #1c1c33;
  --border-line: #d4d6dc;
  --default-text-color: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  -webkit-appearance: none;
}

input {
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--default-text-color);
  text-align: left;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1652f0;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1138ff;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

span {
  color: var(--default-text-color);
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #aeaed5;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--default-text-color);
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--border-line);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #000;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1199px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1199px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #a1a0a7;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--border-line);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--border-line);
}
.table tbody + tbody {
  border-top: 2px solid var(--border-line);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid var(--border-line);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid var(--border-line);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #a1a0a7;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2d9ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #abb9ff;
}

.table-hover .table-primary:hover {
  background-color: #b9c3ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b9c3ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4c8eb;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b099da;
}

.table-hover .table-secondary:hover {
  background-color: #c5b5e4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c5b5e4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bcf4d9;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #83ebb8;
}

.table-hover .table-success:hover {
  background-color: #a6f0cd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a6f0cd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c3cff8;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8fa6f2;
}

.table-hover .table-info:hover {
  background-color: #acbdf5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #acbdf5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe1c5;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fec794;
}

.table-hover .table-warning:hover {
  background-color: #ffd4ac;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffd4ac;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8b8b8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f17b7b;
}

.table-hover .table-danger:hover {
  background-color: #f6a1a1;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6a1a1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbec1;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #84868c;
}

.table-hover .table-dark:hover {
  background-color: #b0b1b5;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0b1b5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: var(--border-line);
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dde2ff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #aeaed5;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #10d876;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(16, 216, 118, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #10d876;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #10d876;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #10d876;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #10d876;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #10d876;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2cef8f;
  background-color: #2cef8f;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #10d876;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #10d876;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e50202;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(229, 2, 2, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e50202;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e50202;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e50202;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e50202;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e50202;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd1d1d;
  background-color: #fd1d1d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e50202;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e50202;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #a1a0a7;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #a1a0a7;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3758ff;
  border-color: #2a4eff;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #3758ff;
  border-color: #2a4eff;
  box-shadow: 0 0 0 0rem rgba(117, 140, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2a4eff;
  border-color: #1d43ff;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(117, 140, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #57329a;
  border-color: #512f90;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #57329a;
  border-color: #512f90;
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #512f90;
  border-color: #4c2b87;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-success:hover {
  color: #fff;
  background-color: #0db463;
  border-color: #0ca95c;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #0db463;
  border-color: #0ca95c;
  box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0ca95c;
  border-color: #0c9d56;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-info:hover {
  color: #fff;
  background-color: #1843cf;
  border-color: #173fc3;
}
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #1843cf;
  border-color: #173fc3;
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #173fc3;
  border-color: #153cb8;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fe9431;
  border-color: #fe9431;
}
.btn-warning:hover {
  color: #212529;
  background-color: #fe800b;
  border-color: #fb7a01;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #fe800b;
  border-color: #fb7a01;
  box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #fe9431;
  border-color: #fe9431;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #fb7a01;
  border-color: #ee7401;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bf0202;
  border-color: #b20202;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #bf0202;
  border-color: #b20202;
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b20202;
  border-color: #a60101;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #131722;
  border-color: #131722;
}
.btn-dark:hover {
  color: #fff;
  background-color: #050609;
  border-color: #010101;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #050609;
  border-color: #010101;
  box-shadow: 0 0 0 0rem rgba(54, 58, 67, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #131722;
  border-color: #131722;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #010101;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(54, 58, 67, 0.5);
}

.btn-outline-primary {
  color: #1652f0;
  border-color: #1652f0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #1652f0;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.5);
}

.btn-outline-secondary {
  color: #673bb7;
  border-color: #673bb7;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #673bb7;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.btn-outline-success {
  color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #10d876;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.btn-outline-info {
  color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #2754e6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.btn-outline-warning {
  color: #fe9431;
  border-color: #fe9431;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #fe9431;
  border-color: #fe9431;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fe9431;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fe9431;
  border-color: #fe9431;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.btn-outline-danger {
  color: #e50202;
  border-color: #e50202;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e50202;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #131722;
  border-color: #131722;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #131722;
  border-color: #131722;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0rem rgba(19, 23, 34, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #131722;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #131722;
  border-color: #131722;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(19, 23, 34, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1652f0;
  text-decoration: none;
}
.btn-link:hover {
  color: #1138ff;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #a1a0a7;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--border-line);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--default-text-color);
  text-decoration: none;
  background-color: #1652f0;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1652f0;
  background-color: #1652f0;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dde2ff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1652f0;
  background-color: #1652f0;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 120, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(93, 120, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 120, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 120, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #dde2ff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #dde2ff;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #000, 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #000, 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #000, 0 0 0 0rem rgba(93, 120, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1652f0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1652f0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: white;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0rem;
  margin-left: 0rem;
  background-color: #1652f0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: white;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #000;
  border-color: #dee2e6 #dee2e6 #000;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1652f0;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1439.98) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid var(--border-line);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: var(--border-line);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: var(--border-line);
  border-top: 1px solid var(--border-line);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1652f0;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1138ff;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1652f0;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #2a4eff;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #673bb7;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #512f90;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #10d876;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #0ca95c;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #2754e6;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #173fc3;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fe9431;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #fb7a01;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e50202;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #b20202;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #131722;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #010101;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(19, 23, 34, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #303e85;
  background-color: #dfe4ff;
  border-color: #d2d9ff;
}
.alert-primary hr {
  border-top-color: #b9c3ff;
}
.alert-primary .alert-link {
  color: #222d60;
}

.alert-secondary {
  color: #361f5f;
  background-color: #e1d8f1;
  border-color: #d4c8eb;
}
.alert-secondary hr {
  border-top-color: #c5b5e4;
}
.alert-secondary .alert-link {
  color: #201239;
}

.alert-success {
  color: #08703d;
  background-color: #cff7e4;
  border-color: #bcf4d9;
}
.alert-success hr {
  border-top-color: #a6f0cd;
}
.alert-success .alert-link {
  color: #054023;
}

.alert-info {
  color: #142c78;
  background-color: #d4ddfa;
  border-color: #c3cff8;
}
.alert-info hr {
  border-top-color: #acbdf5;
}
.alert-info .alert-link {
  color: #0d1c4c;
}

.alert-warning {
  color: #844d19;
  background-color: #ffead6;
  border-color: #ffe1c5;
}
.alert-warning hr {
  border-top-color: #ffd4ac;
}
.alert-warning .alert-link {
  color: #593411;
}

.alert-danger {
  color: #770101;
  background-color: #facccc;
  border-color: #f8b8b8;
}
.alert-danger hr {
  border-top-color: #f6a1a1;
}
.alert-danger .alert-link {
  color: #440101;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #0a0c12;
  background-color: #d0d1d3;
  border-color: #bdbec1;
}
.alert-dark hr {
  border-top-color: #b0b1b5;
}
.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1652f0;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #a1a0a7;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1652f0;
  border-color: #1652f0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #303e85;
  background-color: #d2d9ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #303e85;
  background-color: #b9c3ff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #303e85;
  border-color: #303e85;
}

.list-group-item-secondary {
  color: #361f5f;
  background-color: #d4c8eb;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #361f5f;
  background-color: #c5b5e4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #361f5f;
  border-color: #361f5f;
}

.list-group-item-success {
  color: #08703d;
  background-color: #bcf4d9;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #08703d;
  background-color: #a6f0cd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #08703d;
  border-color: #08703d;
}

.list-group-item-info {
  color: #142c78;
  background-color: #c3cff8;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #142c78;
  background-color: #acbdf5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #142c78;
  border-color: #142c78;
}

.list-group-item-warning {
  color: #844d19;
  background-color: #ffe1c5;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #844d19;
  background-color: #ffd4ac;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #844d19;
  border-color: #844d19;
}

.list-group-item-danger {
  color: #770101;
  background-color: #f8b8b8;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #770101;
  background-color: #f6a1a1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #770101;
  border-color: #770101;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #0a0c12;
  background-color: #bdbec1;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0a0c12;
  background-color: #b0b1b5;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0a0c12;
  border-color: #0a0c12;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--border-line);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid var(--border-line);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #a1a0a7;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1652f0 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2a4eff !important;
}

.bg-secondary {
  background-color: #673bb7 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #512f90 !important;
}

.bg-success {
  background-color: #10d876 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0ca95c !important;
}

.bg-info {
  background-color: #2754e6 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #173fc3 !important;
}

.bg-warning {
  background-color: #fe9431 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fb7a01 !important;
}

.bg-danger {
  background-color: #e50202 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b20202 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #131722 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #010101 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid var(--border-line) !important;
}

.border-top {
  border-top: 1px solid var(--border-line) !important;
}

.border-right {
  border-right: 1px solid var(--border-line) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--border-line) !important;
}

.border-left {
  border-left: 1px solid var(--border-line) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1652f0 !important;
}

.border-secondary {
  border-color: #673bb7 !important;
}

.border-success {
  border-color: #10d876 !important;
}

.border-info {
  border-color: #2754e6 !important;
}

.border-warning {
  border-color: #fe9431 !important;
}

.border-danger {
  border-color: #e50202 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #131722 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1440) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1440) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1652f0 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #1138ff !important;
}

.text-secondary {
  color: #673bb7 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #46287d !important;
}

.text-success {
  color: #10d876 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #0b914f !important;
}

.text-info {
  color: #2754e6 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #1438ad !important;
}

.text-warning {
  color: #fe9431 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #e16d01 !important;
}

.text-danger {
  color: #e50202 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #990101 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #131722 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #a1a0a7 !important;
}

.text-muted {
  color: #aeaed5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: var(--border-line);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: var(--border-line);
  }
}

* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.75;
}
body.fixed {
  overflow: hidden;
  position: fixed;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}
a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: #1652f0;
}

b,
strong,
.strong {
  font-weight: 500;
  color: #fff;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 6px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 999999999;
}

.sk-three-bounce {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #000;
}

.sk-three-bounce .sk-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #1652f0;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.mw-150 {
  max-width: 150px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.section-padding {
  padding: 100px 0px;
}
@media only screen and (max-width: 1199px) {
  .section-padding {
    padding: 70px 0px;
  }
}

.section-title {
  margin-bottom: 75px;
}
.section-title h2 {
  text-align: center;
  font-weight: 500;
}

#toast-container {
  top: 48px;
  right: 15px;
}
#toast-container > div:hover {
  -moz-box-shadow: 0px;
  box-shadow: 0px;
  cursor: pointer;
}
#toast-container .toast {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  border-radius: 16px;
  width: 500px;
  padding: 10px 20px 15px 120px;
}
@media only screen and (max-width: 575px) {
  #toast-container .toast {
    width: auto;
  }
}
#toast-container .toast:hover {
  -moz-box-shadow: 0px;
  box-shadow: 0px;
}
#toast-container canvas {
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
#toast-container .toast-message {
  max-width: 300px;
}
#toast-container .progress_count {
  position: absolute;
  color: #131722;
  font-size: 18px;
  left: 36px;
  text-align: center;
  right: 0;
  width: 50px;
  height: 100%;
  top: 50px;
}
#toast-container .la-close {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 24px;
  font-size: 24px;
}
#toast-container a {
  display: inline-block;
  width: 80px;
  padding: 4px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  right: 20px;
  background: #ffdeb0;
  border-radius: 30px;
  color: #fe9431;
}

.toast-progress {
  background: #fe9431;
  opacity: 6;
}

.p2col {
  column-count: 2;
  column-gap: 50px;
}
@media only screen and (max-width: 767px) {
  .p2col {
    column-count: 1;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.thumb-style {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #1652f0;
  border: 1px solid #2a4eff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 5000;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.thumb-style_hover {
  background: #2a4eff;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.slider-container {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 15px 0 20px;
}

input[type='range'] {
  position: relative;
  z-index: 1000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  height: 15px;
  border-radius: 3px;
  outline: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.9);
}
input[type='range']::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1652f0;
  border: 1px solid #2a4eff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 5000;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
input[type='range']::-webkit-slider-thumb:hover,
input[type='range']::-webkit-slider-thumb:active {
  background: #2a4eff;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
input[type='range']::-moz-range-track {
  background: transparent;
}
input[type='range']::-moz-range-thumb {
  position: relative;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1652f0;
  border: 1px solid #2a4eff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 5000;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}
input[type='range']::-moz-range-thumb:hover,
input[type='range']::-moz-range-thumb:active {
  background: #2a4eff;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.fill {
  position: absolute;
  z-index: 50;
  top: 2px;
  height: 15px;
  width: 0%;
  border-radius: 3px;
  background: -webkit-gradient(linear, left top, right top, from(#d6e6fa), to(#2a4eff));
  background: linear-gradient(to right, #d6e6fa 0%, #2a4eff 100%);
}

.count {
  position: absolute;
  top: -25px;
  left: 0;
  border: 1px solid #2a4eff;
  background: #1652f0;
  color: #fff;
  padding: 2px 5px;
  border-radius: 20px;
  text-align: center;
  margin-left: 0px;
  font-size: 11px;
}

.dropdown-menu {
  background-color: #131722;
  box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);
}
.dropdown-menu .dropdown-item {
  border-top: 1px solid var(--border-line);
  color: #a1a0a7;
}
.dropdown-menu .dropdown-item:first-child {
  border: 0px;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item.active {
  background-color: transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.card {
  border: 0px solid var(--border-line);
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background: var(--card-background);
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-line);
  background: var(--card-background);
  padding: 15px 20px;
}
.card-title {
  font-size: 16px;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 15px 20px;
  background: var(--card-background);
  border-radius: 15px;
}

.form-group {
  position: relative;
}
.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #a1a0a7;
  margin-bottom: 10px;
  background: transparent;
  border-color: var(--border-line);
}

.form-control {
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #a1a0a7;
  transition: all 0.3s ease-in-out;
  background: #fff;
}
.form-control .select {
  padding: 12px 0;
}
.form-control span {
  margin-top: 0;
}
.form-control::-webkit-input-placeholder {
  color: #a1a0a7;
}
.form-control:-ms-input-placeholder {
  color: #a1a0a7;
}
.form-control::placeholder {
  color: #a1a0a7;
}
.form-control:hover {
  box-shadow: none !important;
  outline: none;
  border-color: var(--border-line);
  color: #a1a0a7;
  background: #000;
}
.form-control:focus,
.form-control:active,
.form-control.active {
  box-shadow: none !important;
  outline: none;
  border-color: #1652f0;
  color: #a1a0a7;
  background: #000;
}

input:-internal-autofill-selected {
  background: #050505 !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #000;
  margin-bottom: 0px !important;
  color: #a1a0a7;
  border-color: transparent;
}

.input-group-append .input-group-text {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group-prepend .input-group-text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/*Styling Selectbox*/
.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: black;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: '';
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #1652f0;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid var(--border-line);
  border-radius: 5px;
  color: #aeaed5;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}
.file-upload-wrapper:before {
  content: 'Upload';
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: #1652f0;
  color: #fff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #2a4eff;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: black;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #a1a0a7;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #a1a0a7;
  font-size: 0.875rem;
  text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: #1652f0;
  color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: #1652f0;
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #f6f8ff;
  color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #a1a0a7;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #e50202;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #ffffff;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #131722;
  color: #1652f0;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}
.table tr td,
.table tr th {
  border: 0px;
  vertical-align: middle;
  padding: 15px;
}
.table tr td:last-child,
.table tr th:last-child {
  text-align: right;
}

#dashboard .table tr td,
#dashboard .table tr th {
  padding: 5px 7px;
}

.table tr th {
  padding: 0px 5px;
  font-weight: 500;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #000;
}

.table-striped tfoot {
  border-top: 2px solid var(--border-line);
}
.table-striped tfoot tr th {
  padding-top: 10px;
}

.scroll-wrapper,
.market-limit,
.order-book,
.trade-history,
.depth-chart,
.open-orders .buy-sell-limit {
  position: relative;
  height: 400px;
  margin-bottom: 10px;
}

.price-pair,
.balance-widget,
.market-news,
.your-position {
  position: relative;
  height: 400px;
  margin-bottom: 10px;
}

.limit-nested {
  position: relative;
  height: 210px;
}

.market-nested {
  position: relative;
  height: 270px;
}

.stop-limit-nested {
  position: relative;
  height: 165px;
}

.open-position-table {
  position: relative;
  height: 400px;
  margin-bottom: 10px;
}

.card-header .nav-pills {
  line-height: initial;
}
.card-header .nav-pills a {
  padding: 0px 7px;
  color: #a1a0a7;
  font-weight: 500;
}
.card-header .nav-pills a.active {
  background-color: transparent !important;
  color: #1652f0 !important;
  opacity: 1;
}

.order-book-divider {
  text-align: center;
  padding: 15px 0px;
  font-family: 'Barlow', sans-serif;
}
.order-book-divider h6 {
  margin-bottom: 0px;
}

#chartdiv {
  width: 100%;
  height: 380px;
}

.duration-option {
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
}
.duration-option a {
  display: inline-block;
  margin-left: 10px;
  text-align: center;
  padding: 5px 15px;
  border: 1px solid var(--border-line);
  transition: all 0.3s ease;
  font-weight: 500;
  border-radius: 5px;
}
.duration-option a :hover,
.duration-option a:focus,
.duration-option a.active {
  background: #1652f0;
  color: #fff;
  border-right: 1px solid transparent;
}

.market-news img {
  border-radius: 5px;
}

.list-group-item {
  background-color: #131722;
}

.total-balance {
  padding: 10px 0px 20px;
  text-align: center;
}

.open-position-table table tr th,
.open-position-table table tr td {
  padding: 10px 10px !important;
}

.intro {
  padding: 100px 0px;
}
.intro .intro-content h1 {
  line-height: 55px;
  margin-bottom: 30px;
  color: var(--default-text-color);
}
.intro .intro-content p {
  font-size: 20px;
  margin-bottom: 50px;
}

.intro-form {
  position: relative;
  max-width: 450px;
  margin: 0 auto 50px;
}
.intro-form form {
  display: flex;
}
.intro-form button {
  position: relative;
  background: #1652f0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 7px 20px;
  color: #fff;
  font-size: 24px;
  overflow: hidden;
  width: 60px;
  min-width: 60px;
  border: 0px;
  margin-left: 15px;
}
.intro-form button .first {
  position: absolute;
  right: 17px;
  top: 12px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.intro-form button .second {
  position: absolute;
  left: -22px;
  top: 12px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.intro-form button:hover,
.intro-form button:focus {
  outline: none;
}
.intro-form button:hover .first,
.intro-form button:focus .first {
  right: -22px;
}
.intro-form button:hover .second,
.intro-form button:focus .second {
  left: 20px;
}

@media only screen and (max-width: 991px) {
  .intro-btn {
    margin-bottom: 50px;
  }
}

.intro-btn .btn-primary {
  padding: 10px 30px;
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px;
}

.intro-btn .btn-outline-primary {
  padding: 10px 30px;
  font-weight: 500;
  font-size: 16px;
}

.intro-form-exchange {
  padding: 30px;
  box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  border-radius: 5px;
  background: var(--card-background);
}
.intro-form-exchange .nice-select {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.intro-form-exchange .btn {
  padding: 10px 15px;
  font-weight: 500;
  position: relative;
  font-size: 16px;
}
.intro-form-exchange .btn i {
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  right: 15px;
}

.sponsor {
  border-top: 1px solid var(--border-line);
  border-bottom: 1px solid var(--border-line);
}

.sponsor-logo {
  display: flex !important;
  justify-content: space-between;
}
.sponsor-logo a {
  display: block;
  margin-bottom: 50px;
}

.trusted-logo {
  text-align: center;
  margin-bottom: 30px;
}
.trusted-logo a {
  display: inline-block;
}
.trusted-logo a img {
  filter: brightness(100);
}

.features-content {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .features-content {
    margin-bottom: 50px;
  }
}
.features-content span i {
  color: #1652f0;
  font-size: 36px;
}
.features-content h4 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.features-content p {
  line-height: 27px;
  max-width: 350px;
  margin: 0 auto 30px;
}
.features-content a {
  color: #1652f0;
  font-size: 14px;
}

.get-touch-content {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  background: #ffffff;
  border-radius: 5px;
}
.get-touch-content span {
  margin-right: 15px;
}
.get-touch-content span i {
  color: #1652f0;
  font-size: 42px;
}
.get-touch-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.get-touch-content p {
  line-height: 27px;
}

.testimonial-content {
  background: #ffffff;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
  border-radius: 5px;
}
.testimonial-content .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: -29px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  min-width: auto;
  border-radius: 50px;
  background: #1652f0;
  color: #fff;
  font-size: 18px;
}
.testimonial-content .owl-carousel .owl-nav .owl-prev:hover,
.testimonial-content .owl-carousel .owl-nav .owl-prev:focus {
  outline: none;
}
.testimonial-content .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: -29px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  min-width: auto;
  border-radius: 50px;
  background: #1652f0;
  color: #fff;
  font-size: 18px;
}
.testimonial-content .owl-carousel .owl-nav .owl-next:hover,
.testimonial-content .owl-carousel .owl-nav .owl-next:focus {
  outline: none;
}

.customer-img img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}
.customer-img img::before {
  position: absolute;
  content: '';
}

.customer-review {
  padding: 30px;
}
.customer-review img {
  width: auto !important;
  margin-bottom: 30px;
  filter: brightness(100);
}
.customer-review p {
  margin-bottom: 0px;
}

.customer-info {
  margin-top: 20px;
}
.customer-info h6 {
  font-size: 18px;
}

@media only screen and (max-width: 991px) {
  .appss-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.appss-content h2 {
  margin-bottom: 30px;
  font-weight: 500;
}

.appss-content ul li {
  padding: 10px 0;
  font-size: 17px;
}
.appss-content ul li i {
  font-weight: bold;
  font-size: 18px;
  color: #1652f0;
  margin-right: 15px;
}

.appss-content .btn {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  margin-right: 15px;
}

.whatsNew-tab .nav {
  flex-direction: column;
}
.whatsNew-tab .nav .nav-item {
  margin-bottom: 30px;
}
.whatsNew-tab .nav .nav-item .nav-link {
  background: transparent;
  color: #fff;
  font-size: 20px;
  padding: 15px 15px;
  border: 1px solid #1652f0;
  transition: all 0.5s ease-in-out;
}
.whatsNew-tab .nav .nav-item .nav-link:hover,
.whatsNew-tab .nav .nav-item .nav-link:focus,
.whatsNew-tab .nav .nav-item .nav-link.active {
  background: white;
  color: #1652f0;
  border: 1px solid #1652f0;
}

.accordion {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.accordion__item {
  margin-bottom: -1px;
}
.accordion__header {
  padding: 0.9375rem;
  border: 1px solid var(--border-line);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.accordion__header--indicator {
  font-family: 'LineAwesome';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
}
.accordion__header--indicator.indicator_bordered {
  display: inline-block;
  width: 25px;
  text-align: center;
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 50%;
  line-height: 25px;
}
.accordion__header:not(.collapsed) .accordion__header--indicator::before {
  content: '\f110';
}
.accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
  content: '\f113';
}
.accordion__header.collapsed .accordion__header--indicator::before {
  content: '\f113';
}
.accordion__header.collapsed .accordion__header--indicator.style_two::before {
  content: '\f110';
}
.accordion__body--text {
  padding: 0.9375rem;
}
.accordion-active-header .accordion__header:not(.collapsed) {
  background-color: #1652f0;
  color: #fff;
}

.getstart-content {
  text-align: center;
}
.getstart-content i {
  font-size: 48px;
  color: #1652f0;
}
.getstart-content h3 {
  margin-top: 15px;
  font-size: 20px;
}

.promo .promo-content {
  text-align: center;
}
.promo .promo-content .promo-content-img {
  background: #1652f0;
  height: 150px;
  width: 150px;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}
.promo .promo-content .promo-content-img img {
  max-width: 70px;
}
.promo .promo-content h3 {
  margin-bottom: 15px;
}
.promo .promo-content p {
  line-height: 30px;
}

.portfolio_list .media {
  margin-bottom: 30px;
}
.portfolio_list .media h4 {
  font-size: 22px;
}
.portfolio_list .media .port-icon {
  height: 65px;
  width: 65px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin-right: 20px;
  color: #1652f0;
}
.portfolio_list .media p {
  margin-bottom: 0px;
}
.portfolio_list .media p a {
  color: #1652f0;
}

.portfolio_img img {
  border-radius: 5px;
  box-shadow: 25px 60px 125px -25px rgba(0, 0, 0, 0.2), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);
}

.trade-app-content {
  border: 0px;
  background: #ffffff;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
}
.trade-app-content .card-body {
  padding: 20px 30px;
}
.trade-app-content .card-body span {
  font-size: 48px;
  margin-bottom: 15px;
  display: inline-block;
  color: #1652f0;
}
.trade-app-content .card-body h4 {
  font-size: 20px;
  margin-bottom: 15px;
}
.trade-app-content .card-body p {
  min-height: 115px;
}
@media only screen and (max-width: 1199px) {
  .trade-app-content .card-body p {
    min-height: 165px;
  }
}
@media only screen and (max-width: 991px) {
  .trade-app-content .card-body p {
    min-height: auto;
  }
}
.trade-app-content .card-body a {
  color: #1652f0;
  display: flex;
  font-weight: 500;
  align-items: center;
}
.trade-app-content .card-body a i {
  margin-left: 15px;
  font-size: 18px;
  font-weight: bold;
}

.our_vision h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 28px;
}

.our_vision p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
}

.service-img img {
  border-radius: 5px;
}

.profile_card .media {
  border-bottom: 1px solid var(--border-line);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.card-profile__info h5 {
  display: inline-block;
}

.social-icons {
  margin-top: 20px;
}
.social-icons a {
  border-radius: 100px;
  padding: 7px 0px;
  margin-right: 15px;
  height: 42px;
  width: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #1652f0;
  text-align: center;
}

.acc_balance .btn-group {
  width: 100%;
}

.linked_account .media {
  align-items: center;
  border-radius: 5px;
}
.linked_account .media > span {
  background: #1652f0;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.linked_account .media .media-body p {
  margin-bottom: 0px;
}

.linked_account .edit-option a {
  display: inline-block;
  padding: 5px;
  font-size: 18px;
}
.linked_account .edit-option a:last-child {
  color: #e50202;
}

.linked_account .verify .verified,
.linked_account .verify .not-verify {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.linked_account .verify .verified span,
.linked_account .verify .not-verify span {
  background: #10d876;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 15px;
  font-weight: bold;
}

.linked_account .verify .not-verify span {
  background: #e50202;
}

#deposits .nav-tabs {
  border-color: var(--border-line);
}
#deposits .nav-tabs li a {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #a1a0a7;
  border-color: var(--border-line);
}
#deposits .nav-tabs li a.active {
  background: #1652f0;
  color: #fff;
  border-bottom: 1px solid #1652f0;
}

#deposits .tab-content .qrcode {
  text-align: center;
  margin-top: 50px;
  padding: 20px 0;
}

#deposits .tab-content form {
  max-width: 750px;
  margin: 30px auto 50px;
}

#deposits .tab-content ul {
  max-width: 750px;
}
#deposits .tab-content ul li {
  padding-left: 25px;
  position: relative;
  padding-bottom: 7px;
}
#deposits .tab-content ul li i {
  font-size: 16px;
  margin-right: 7px;
  position: absolute;
  left: 0;
}

.important-info ul {
  max-width: 850px;
}
.important-info ul li {
  padding-left: 25px;
  position: relative;
  padding-bottom: 7px;
}
.important-info ul li i {
  font-size: 16px;
  margin-right: 7px;
  position: absolute;
  left: 0;
}

.authincation {
  height: 100%;
}

.auth-form {
  border: 0px solid var(--border-line);
}
.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.verification {
  height: 100%;
}

.identity-content {
  text-align: center;
}
.identity-content .icon {
  display: flex;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  margin: 0 auto;
  background: #10d876;
  color: #097942;
  font-size: 30px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.identity-content h4 {
  max-width: 350px;
  margin: 0 auto 15px;
}
.identity-content p {
  max-width: 350px;
  margin: 0 auto 30px;
}

.blog-grid .card {
  border: 0px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  margin-bottom: 30px;
  background: #ffffff;
}
.blog-grid .card img {
  max-height: 185px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .blog-grid .card img {
    max-height: 150px;
  }
}
@media only screen and (max-width: 991px) {
  .blog-grid .card img {
    max-height: 100%;
  }
}
.blog-grid .card .card-body {
  padding: 20px !important;
}
.blog-grid .card .card-body .card-title {
  font-size: 20px;
  margin-bottom: 15px;
}
.blog-grid .card .card-footer {
  padding: 10px 30px 30px;
  background: #ffffff;
  border: 0px;
}
.blog-grid .card .card-footer .meta-info {
  display: flex;
  justify-content: space-between;
}
.blog-grid .card .card-footer .meta-info .author img {
  max-width: 30px;
  border-radius: 50px;
  margin-right: 7px;
}
.blog-grid .card .card-footer .meta-info a {
  color: #a1a0a7;
  font-size: 14px;
}
.blog-grid .card .card-footer .meta-info a:hover {
  color: #1652f0;
}

.blog-sidebar .widget-search {
  position: relative;
}
.blog-sidebar .widget-search input {
  color: #677294;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid var(--border-line);
  padding-left: 30px;
  height: 55px;
  z-index: 0;
}
.blog-sidebar .widget-search span {
  border: 0px;
  border-radius: 0px;
  font-size: 16px;
  color: #282835;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 18px;
  padding: 0px;
  z-index: 1;
  cursor: pointer;
}

.blog-sidebar .widget-recent-post {
  margin-top: 50px;
}
.blog-sidebar .widget-recent-post .post-title {
  font-size: 20px;
  border-bottom: 1px solid var(--border-line);
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.blog-sidebar .widget-recent-post .media img {
  max-width: 60px;
  border-radius: 5px;
}
.blog-sidebar .widget-recent-post .media h5 {
  font-size: 16px;
}
.blog-sidebar .widget-recent-post .meta-info a {
  font-size: 14px;
  color: #a1a0a7;
  margin-right: 5px;
}

.blog-sidebar .widget-category {
  margin-top: 50px;
}
.blog-sidebar .widget-category .widget-title {
  font-size: 20px;
  border-bottom: 1px solid var(--border-line);
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.blog-sidebar .widget-category .list-group-item {
  background: #ffffff;
  border: 0px;
  color: #a1a0a7;
}
.blog-sidebar .widget-category .list-group-item .badge {
  background: #1652f0;
  border-radius: 5px;
  padding: 5px 7px;
}

.blog-sidebar .widget-tag {
  margin-top: 50px;
}
.blog-sidebar .widget-tag .widget-title {
  font-size: 20px;
  border-bottom: 1px solid var(--border-line);
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.blog-sidebar .widget-tag .tag-group a {
  color: #a1a0a7;
  border: 1px solid var(--border-line);
  border-radius: 3px;
  padding: 5px 15px;
  float: left;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 5px;
  font-size: 14px;
}

.blog-single-details {
  float: left;
  width: 100%;
}

.blog-single-post {
  float: left;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.blog-single-post > ul.post-nfo {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  margin-top: -1px;
}

.post_info > ul.post-nfo {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  margin-top: -1px;
}

.post-nfo li {
  display: inline-block;
  margin-right: 20px;
}
.post-nfo li a {
  display: inline-block;
  color: #a1a0a7;
  font-size: 16px;
  margin-right: 20px;
  margin-right: 0;
}
.post-nfo li i {
  font-size: 16px;
  padding-right: 6px;
  position: relative;
  top: 1px;
}

.pt {
  padding: 105px 0;
}

.blog-single-sec.section-padding {
  padding: 110px 0 110px 0;
}

.blog-single-post > h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%;
}

.post_info > h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%;
}

.blog-img {
  float: left;
  width: 100%;
  margin-bottom: 34px;
  position: relative;
}
.blog-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background-color: rgba(127, 142, 233, 0.85);
  opacity: 0;
  visibility: hidden;
}

.agent_img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background-color: rgba(127, 142, 233, 0.85);
  opacity: 0;
  visibility: hidden;
}

.blog-single-post:hover .blog-img:before,
.agent:hover .agent_img:before {
  opacity: 1;
  visibility: visible;
  left: 0;
  width: 100%;
}

.blog-single-post.single {
  cursor: inherit;
}
.blog-single-post.single .blog-img:before,
.blog-single-post.single .blog-img:after {
  display: none;
}

.blog-img > a img {
  width: 100%;
  float: left;
}

.blog-single-post p {
  float: left;
  width: 100%;
  margin-bottom: 24px;
}

.blog-single-post > blockquote {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  border-left: 3px solid;
  padding: 2px 0 1px 18px;
  float: left;
  width: 100%;
  margin-bottom: 38px;
  margin-top: 13px;
}

.blg-dv {
  float: left;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 8px;
}

.blg-sm {
  float: left;
  width: 100%;
}

.blg-info {
  float: left;
  width: 100%;
  padding-left: 27px;
}

.bg-links {
  float: left;
  width: 100%;
  padding: 0 40px;
  margin-top: 5px;
  margin-bottom: 13px;
}
.bg-links li {
  float: left;
  width: 100%;
  color: #fff;
  margin-bottom: 17px;
  position: relative;
  padding-left: 19px;
}
.bg-links li :before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* ================ post-share =============== */
.post-share {
  float: left;
  width: 100%;
  padding: 21px 0;
  border-top: 1px solid var(--border-line);
  margin-top: 8px;
  margin-bottom: 27px;
}

.social-links {
  float: left;
}
.social-links li {
  display: inline-block;
  margin-right: 17px;
}
.social-links li a {
  display: inline-block;
  color: #1652f0;
  font-size: 14px;
}

.post-share > a {
  float: right;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
}
.post-share > a i {
  padding-left: 8px;
}

/* ============== cm-info-sec ================ */
.cm-info-sec {
  float: left;
  width: 100%;
  display: table;
  border: 1px solid var(--border-line);
  padding: 29px 24px;
  margin-bottom: 68px;
}

.cm-img {
  display: table-cell;
}
.cm-img > img {
  float: left;
}

.cm-info {
  display: table-cell;
  width: 100%;
  vertical-align: top;
  padding-left: 19px;
  position: relative;
  top: -1px;
}
.cm-info h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
.cm-info > p {
  line-height: 24px;
  margin-bottom: 12px;
}

/* ================= comment-section ================  */
.comment-section {
  float: left;
  width: 100%;
  margin-top: 7px;
}

.p-title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.comment-section .cm-info-sec {
  padding: 0;
  border: 0;
  margin: 0;
}

.comment-section .cm-info {
  padding-top: 7px;
}
.comment-section .cm-info > h4 {
  color: #a1a0a7;
  font-size: 14px;
  font-weight: 400;
}
.comment-section .cm-info h3 {
  margin-bottom: 3px;
}

.comment-section > ul {
  float: left;
  width: 100%;
}
.comment-section > ul > li {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 32px;
}

.comment-section ul li p {
  float: left;
  width: 100%;
  margin-top: 13px;
}

.cm-reply {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 7px;
  right: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* ================ post-comment-sec =============== */
.post-comment-sec {
  float: left;
  width: 100%;
  margin-top: 22px;
}
.post-comment-sec .p-title {
  margin-bottom: 18px;
}
.post-comment-sec > form {
  float: left;
  width: 100%;
}
.post-comment-sec .col-lg-4 {
  padding: 0 8px;
}

.buy-sell-widget .nav-tabs {
  margin-bottom: 30px;
  border: 0px;
}
.buy-sell-widget .nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.buy-sell-widget .nav-tabs .nav-item .nav-link {
  padding: 10px 10px;
  font-size: 18px;
  text-transform: uppercase;
  border: 0px;
  font-weight: 500;
  border-radius: 5px;
  background: transparent;
  border: 1px solid var(--border-line);
  margin: 0px 15px;
}
.buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #1652f0;
  color: white;
}

@media only screen and (max-width: 767px) {
  .buyer-seller .d-flex .buyer-info,
  .buyer-seller .d-flex .seller-info {
    padding: 10px;
  }
  .buyer-seller .d-flex .buyer-info img,
  .buyer-seller .d-flex .seller-info img {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .buyer-seller .d-flex {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 575px) {
  .buyer-seller .d-flex {
    flex-direction: column;
  }
  .buyer-seller .d-flex .seller-info {
    text-align: left !important;
  }
}

#accordion-faq {
  margin-top: -15px;
  box-shadow: none;
}
#accordion-faq .card {
  margin-bottom: 0px;
  border: 0px solid var(--border-line);
  box-shadow: none;
}
#accordion-faq .card .card-header {
  border: 0px;
  padding: 15px 0px 10px;
}
#accordion-faq .card .card-body {
  padding: 0px 0px 10px;
}

.intro-video-play {
  background-color: #fff;
  background-image: url('./../images/background/2.jpg');
  background-size: cover;
  background-position: 0 0;
  min-height: 363px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  z-index: 0;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
}
.intro-video-play::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1652f0;
  opacity: 0.45;
  border-radius: 5px;
  z-index: -1;
}

.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.play-btn a {
  background: #1652f0;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  padding: 27px 0;
  text-align: center;
  cursor: pointer;
}
.play-btn a i {
  color: #fff;
  font-size: 20px;
}

.apps-download {
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
  padding: 0px 20px;
}
.apps-download ::before {
  background-image: url('./../images/app.png');
  background-color: #3a3361;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  opacity: 0.15;
}
.apps-download ::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #1652f0;
  opacity: 0.3;
  z-index: -1;
  border-radius: 5px;
}

.apps-download-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 504px;
}
.apps-download-content h4 {
  padding: 20px;
  color: #fff;
  text-align: center;
  line-height: 1.3;
}
.apps-download-content .btn {
  min-width: 130px;
}
.apps-download-content .btn-primary {
  background: #2a4eff;
}

.transaction-table td {
  padding: 1.25rem 0.75rem;
}

.transaction-table i.cc {
  font-size: 22px;
  margin-right: 10px;
}

.transaction-table .sold-thumb {
  background: #e50202;
  color: #fff;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transaction-table .sold-thumb i {
  font-weight: 700;
}

.transaction-table .buy-thumb {
  background: #10d876;
  color: #fff;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transaction-table .buy-thumb i {
  font-weight: 700;
}

.transaction-table .badge {
  color: #fff;
}

.info-list ul li {
  margin-bottom: 7px;
  color: #a1a0a7;
  font-weight: 500;
  padding: 12px 0;
}
.info-list ul li:hover,
.info-list ul li:focus {
  color: #1652f0;
}
.info-list ul li i {
  margin-right: 15px;
  color: #1652f0;
  font-weight: bold;
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .sub-menu ul {
    display: inline-block !important;
  }
}

.sub-menu ul li a {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  color: #a1a0a7;
}
.sub-menu ul li a:hover,
.sub-menu ul li a:focus,
.sub-menu ul li a:active,
.sub-menu ul li a.active {
  color: #1652f0;
  background-color: transparent;
}

.sub-menu ul li i {
  font-weight: bold;
  margin-right: 7px;
  font-size: 20px;
}

.sub-menu ul li span {
  font-weight: 400;
}

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid var(--border-line);
  padding-top: 30px;
}

.phone_verified {
  display: flex;
  align-items: center;
}
.phone_verified h5 {
  margin-bottom: 0;
  margin-right: 30px;
  color: #a1a0a7;
}
.phone_verified h5 span {
  color: #1652f0;
  padding: 9px 10px;
  border-radius: 50px;
  margin-right: 7px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid var(--border-line);
  height: 40px;
  width: 40px;
  display: inline-block;
}
.phone_verified .verified,
.phone_verified .not-verify {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}
.phone_verified .verified span,
.phone_verified .not-verify span {
  background: #10d876;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 15px;
  font-weight: bold;
  font-size: 12px;
}
.phone_verified .not-verify span {
  background: #e50202;
}

.id_card {
  padding: 20px;
  border: 1px solid var(--border-line);
  border-radius: 5px;
  margin: 20px 0px;
}

.price-grid .card {
  border: 1px solid var(--border-line);
}
.price-grid .card .card-header {
  padding: 15px 15px 0;
  border: 0px;
}
.price-grid .card .card-header .media {
  align-items: center;
}
.price-grid .card .card-header .media-body {
  color: #fff;
}
.price-grid .card .card-header i {
  margin-right: 10px;
  font-size: 30px;
}
.price-grid .card .card-header p {
  font-size: 14px;
}
.price-grid .card .card-body {
  padding: 15px 15px 0px !important;
}
.price-grid .card .card-body h3 {
  font-size: 20px;
  font-weight: 500;
}

.coin-search {
  padding-bottom: 70px;
}
.coin-search .search-box form {
  display: flex;
}
.coin-search .search-box form .btn {
  margin-left: 15px;
  padding: 8px 30px;
}

.sort-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.sort-view .search-timeline a {
  color: #fff;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
  font-weight: 500;
}
.sort-view .search-timeline a:hover,
.sort-view .search-timeline a:focus,
.sort-view .search-timeline a:active,
.sort-view .search-timeline a.active {
  color: #1652f0;
}
.sort-view .coin-view .nav {
  margin-bottom: 0px;
}
.sort-view .coin-view .nav .nav-item:last-child .nav-link {
  margin-right: 0px;
}
.sort-view .coin-view .nav .nav-item .nav-link {
  background: rgba(0, 0, 0, 0.15);
  color: #1652f0;
  margin-right: 10px;
}
.sort-view .coin-view .nav .nav-item .nav-link:hover,
.sort-view .coin-view .nav .nav-item .nav-link:focus,
.sort-view .coin-view .nav .nav-item .nav-link:active,
.sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #1652f0;
  color: #fff;
}

.terms_condition {
  position: relative;
}

.terms_condition-content {
  padding: 80px 0px 80px;
}

.terms_condition-text {
  margin-bottom: 45px;
}
.terms_condition-text:last-child {
  margin-bottom: 0;
}
.terms_condition-text h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.terms_condition-text p {
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
}
.terms_condition-text li {
  display: flex;
}
.terms_condition-text li i {
  line-height: 30px;
  margin-right: 15px;
  font-size: 13px;
  color: #1652f0;
}

.wallet-feature {
  border-top: 1px solid var(--border-line);
}
.wallet-feature .wallet-feature-content {
  margin-bottom: 50px;
  text-align: center;
}
.wallet-feature .wallet-feature-content i {
  font-size: 40px;
  margin-bottom: 20px;
  color: #1652f0;
}
.wallet-feature .wallet-feature-content h4 {
  max-width: 200px;
  margin: 0 auto;
}

.all-coin {
  border-bottom: 1px solid var(--border-line);
}

.all-coins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.all-coins span {
  font-size: 48px;
  width: 100px;
  height: 100px;
  border: 1px solid var(--border-line);
  border-radius: 100%;
  text-align: center;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-coin-content {
  padding: 75px 0 0;
  text-align: center;
}
.all-coin-content h3 {
  margin-bottom: 15px;
}

.join-team {
  border-bottom: 1px solid var(--border-line);
}

.interested-join {
  border-top: 1px solid var(--border-line);
  background: #2a4eff;
}
.interested-join h2 {
  color: #fff;
  margin-bottom: 15px;
}
.interested-join p {
  color: #fff;
}
.interested-join .btn {
  padding: 7px 20px;
  margin-top: 30px;
}

.career .career-content-img img {
  border-radius: 5px;
}

.career .career-content h3 {
  font-weight: 500;
  margin-bottom: 15px;
}

.career .career-content p {
  line-height: 1.75;
}

.helpdesk-search {
  background: rgba(0, 0, 0, 0.15);
}
.helpdesk-search .helpdesk-search-content {
  text-align: center;
}
.helpdesk-search .helpdesk-form form {
  display: flex;
}
.helpdesk-search .helpdesk-form .btn {
  margin-left: 20px;
  padding: 6px 30px;
}

.help-category .help-cat {
  text-align: center;
  margin-bottom: 50px;
}
.help-category .help-cat span {
  font-size: 48px;
  color: #1652f0;
}
.help-category .help-cat h4 {
  font-size: 20px;
  margin-top: 15px;
}

.help-category .sub-cat {
  display: inline-block;
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.help-category .sub-cat h5 {
  color: #1652f0;
  margin-bottom: 0px;
}

.troubleshooting-content h4 {
  border-bottom: 1px solid var(--border-line);
  padding-bottom: 20px;
  margin-top: 50px;
}

.troubleshooting-content ul {
  margin-top: 20px;
}
.troubleshooting-content ul li a {
  color: #a1a0a7;
  display: inline-block;
  padding-bottom: 10px;
}
.troubleshooting-content ul li a:hover,
.troubleshooting-content ul li a:focus,
.troubleshooting-content ul li a:active,
.troubleshooting-content ul li a.active {
  color: #1652f0;
}

.ticket-box-content {
  background: rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 50px 15px;
  border-radius: 5px;
}
.ticket-box-content p {
  padding: 10px 0 20px;
  margin-bottom: 0px;
  font-size: 18px;
}
.ticket-box-content .btn {
  min-width: 150px;
  font-weight: 500;
}

.header {
  padding: 10px 0px;
  background: var(--card-background);
  border-bottom: 0px solid var(--border-line);
  transition: all 0.3s ease-in-out;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.header.fixed {
  padding: 10px 0px;
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
  border-color: transparent;
}
.header .navigation .navbar {
  background-color: transparent !important;
  padding: 0px;
}
.header .navigation .navbar .collapse {
  justify-content: flex-end;
}
.header .navigation .navbar .navbar-nav {
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .header .navigation .navbar .navbar-nav {
    margin-top: 15px;
  }
}
.header .navigation .navbar .navbar-nav > .nav-item {
  display: inline-block;
  padding: 7px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .header .navigation .navbar .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--border-line);
    margin: 0 15px;
    padding: 7px 15px;
  }
}
.header .navigation .navbar .navbar-nav > .nav-item > a {
  font-size: 16px;
  font-weight: 500;
  color: #a1a0a7;
}
.header .navigation .navbar .navbar-nav > .nav-item > a :hover,
.header .navigation .navbar .navbar-nav > .nav-item > a:focus,
.header .navigation .navbar .navbar-nav > .nav-item > a.active {
  color: #1652f0;
}
@media only screen and (max-width: 991px) {
  .header .navigation .navbar .navbar-nav > .nav-item > a::after {
    position: absolute;
    right: 15px;
    top: 25px;
  }
}
@media only screen and (min-width: 991px) {
  .header .navigation .navbar .navbar-nav .dropdown > a {
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu {
    background: rgba(0, 0, 0, 0.15);
    padding: 15px 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    z-index: 1030;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
    border: 0px;
    margin-top: 10px;
    z-index: 998;
    min-width: 10rem;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a {
    display: inline-block;
    color: #a1a0a7;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a:hover,
  .header .navigation .navbar .navbar-nav .dropdown-menu a:focus,
  .header .navigation .navbar .navbar-nav .dropdown-menu a.active {
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .header .navigation .navbar .navbar-nav .nav-item.dropdown:hover a::before {
    opacity: 1;
    bottom: -9px;
  }
  .header .navigation .navbar .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .header .navigation .navbar .navbar-nav .dropdown-menu {
    border: 0px;
    padding: 0px;
    background: rgba(0, 0, 0, 0.15);
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a {
    border-bottom: 1px solid var(--border-line);
    padding: 15px;
    color: #a1a0a7;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a :last-child {
    border: 0px;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a i,
  .header .navigation .navbar .navbar-nav .dropdown-menu a h6 {
    color: #1652f0;
  }
  .header .navigation .navbar .navbar-nav .dropdown-menu a p {
    color: #a1a0a7;
    margin-bottom: 0;
  }
}

.signin-btn .btn {
  padding: 7px 15px !important;
  min-width: 85px !important;
}

@media only screen and (max-width: 991px) {
  .signin-btn {
    position: absolute;
    right: 73px;
    top: 3px;
  }
}

.header.dashboard {
  padding: 5px 10px;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
.header.dashboard .navbar {
  padding: 0px;
}

.header .navbar-brand {
  display: flex;
  align-items: center;
}
.header .navbar-brand img {
  max-width: 150px;
}
.header .navbar-brand span {
  color: #131722;
  font-weight: 500;
  margin-left: 20px;
  font-size: 24px;
}

.header_auth .btn {
  margin-left: 10px;
}
@media only screen and (max-width: 991px) {
  .header_auth .btn {
    padding: 7px;
    min-width: 110px;
  }
}
@media only screen and (max-width: 991px) {
  .header_auth .btn {
    display: none;
  }
}

.navbar-toggler {
  background: rgba(255, 255, 255, 0.2);
  min-width: auto;
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    margin-top: 10px;
    border-radius: 5px;
  }
}

.navbar-nav .nav-item .nav-link {
  color: #fff;
  font-weight: 500;
  opacity: 0.75;
}
.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link.active {
  opacity: 1;
  color: #1652f0;
}

.language {
  margin-right: 20px;
}
.language .icon {
  cursor: pointer;
}
.language .icon i {
  margin-right: 7px;
}
.language .dropdown-menu {
  border: 0px;
  padding: 5px 0px;
  margin: 0px;
  top: 42px;
  min-width: 100px;
  box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);
  border-radius: 5px;
  background-color: #131722;
}
.language .dropdown-menu .dropdown-item {
  border-top: 1px solid var(--border-line);
  color: #a1a0a7;
}
.language .dropdown-menu .dropdown-item:first-child {
  border: 0px;
}
.language .dropdown-menu .dropdown-item:hover,
.language .dropdown-menu .dropdown-item:focus,
.language .dropdown-menu .dropdown-item.active {
  background-color: transparent;
}
.language .dropdown-menu a {
  padding: 5px 20px;
}

.profile_log {
  cursor: pointer;
}
.profile_log .user {
  display: flex;
  align-items: center;
}
.profile_log .user .thumb {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  border: 1px solid #1652f0;
  display: inline-block;
  margin-right: 7px;
  background: #1652f0;
  color: #fff;
  text-align: center;
  padding: 3px 0;
}
.profile_log .user .thumb i {
  font-size: 18px;
  line-height: 1.3;
}
.profile_log .user .arrow i {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-top: 6px;
  display: inline-block;
}
@media only screen and (max-width: 1199px) {
  .profile_log .user .arrow {
    display: none;
  }
}
.profile_log .dropdown-menu {
  border: 0px;
  padding: 0px;
  margin: 0px;
  top: 47px;
  box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);
  border-radius: 5px;
  background-color: var(--card-background);
  min-width: 240px;
}
.profile_log .dropdown-menu .user-email {
  padding: 20px 20px 10px;
}
.profile_log .dropdown-menu .user-email h6 {
  margin-bottom: 0px;
}
.profile_log .dropdown-menu .user-balance {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.profile_log .dropdown-menu .user-balance p {
  margin-bottom: 0px;
  font-weight: 500;
  color: var(--default-text-color);
}
.profile_log .dropdown-menu .dropdown-item {
  padding: 5px 20px;
  border-top: 1px solid var(--border-line);
  font-size: 14px;
  color: #a1a0a7;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.profile_log .dropdown-menu .dropdown-item:first-child {
  border: 0px;
}
.profile_log .dropdown-menu .dropdown-item.logout {
  color: #e50202;
}
.profile_log .dropdown-menu .dropdown-item.logout i {
  color: #e50202;
}
.profile_log .dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 22px;
  color: #1652f0;
  font-weight: bold;
}
.profile_log .dropdown-menu .dropdown-item:hover,
.profile_log .dropdown-menu .dropdown-item:focus,
.profile_log .dropdown-menu .dropdown-item.active {
  background-color: transparent;
}

.page-title {
  padding: 30px 0;
}
.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}
.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.page-title .page-title-content p span {
  font-weight: 500;
  color: #1652f0;
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}
.breadcrumbs li a {
  color: #aeaed5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.breadcrumbs li:first-child a {
  margin-left: 0px;
}
.breadcrumbs li.active a {
  color: #1652f0;
}

.sidebar {
  background: #ffffff;
  position: fixed;
  left: 0;
  height: 100%;
  width: 60px;
  top: 0;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}

.menu {
  margin-top: 100px;
}
.menu ul li {
  text-align: center;
  margin-bottom: 15px;
}
.menu ul li a {
  padding: 2px 10px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 5px;
}
.menu ul li a:hover,
.menu ul li a:focus,
.menu ul li a:active {
  background: #1652f0;
}
.menu ul li a:hover i,
.menu ul li a:focus i,
.menu ul li a:active i {
  color: #fff;
}
.menu ul li a.active {
  background: #1652f0;
}
.menu ul li a.active i {
  color: #fff;
}
.menu ul li a i {
  color: #fff;
  font-size: 20px;
}

.bottom {
  background: #1b2a4e;
}
.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
}
@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}
.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}
.bottom .bottom-widget ul li a {
  color: #a1a0a7;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}

.footer {
  border-top: 1px solid #223461;
  background: #1b2a4e;
  padding: 10px 0px;
}
.footer.dashboard {
  background: #ffffff;
  border-color: var(--border-line);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer .copyright p {
  margin: 0;
  font-size: 14px;
  color: #7f88a6;
}
.footer .copyright p a {
  color: #1652f0;
  font-weight: 500;
}
@media only screen and (max-width: 575px) {
  .footer .copyright p {
    text-align: center;
  }
}
.footer .footer-social {
  text-align: right;
}
@media only screen and (max-width: 575px) {
  .footer .footer-social {
    text-align: center;
  }
}
.footer .footer-social li {
  display: inline-block;
}
.footer .footer-social li a {
  color: #7f88a6;
  padding: 0px 10px;
}

.sidebar-right {
  display: block;
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px;
  margin-top: 5rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}
.sidebar-right .sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 4.75rem;
  right: 100%;
  background-color: #1652f0;
  color: #fff;
  display: inline-block;
  height: 2.125rem;
  width: 2.125rem;
  text-align: center;
  font-size: 1.3125rem;
  line-height: 2.2rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sidebar-right .sidebar-right-trigger:hover {
  color: #fff;
}
.sidebar-right.show {
  right: 0;
  z-index: 999;
}
.sidebar-right .admin-settings p {
  margin-bottom: 0.3125rem;
}
.sidebar-right .admin-settings input[type='radio'] {
  display: none;
}
.sidebar-right .admin-settings input[type='radio'] + label {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 3px 8px;
  transition: all 0.1s ease;
  border-radius: 100px;
}
.sidebar-right .admin-settings input[type='radio']:checked + label {
  position: relative;
}
.sidebar-right .admin-settings input[type='radio']:checked + label::after {
  height: 12px;
  width: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
}
.sidebar-right #nav_header_color_1 + label {
  background-color: #10d078;
}
.sidebar-right #nav_header_color_2 + label {
  background-color: #ff0096;
}
.sidebar-right #nav_header_color_3 + label {
  background-color: #002eff;
}
.sidebar-right #nav_header_color_4 + label {
  background-color: #ff2f00;
}
.sidebar-right #nav_header_color_5 + label {
  background-color: #00ccd3;
}
.sidebar-right #nav_header_color_1 + label {
  border: 1px solid var(--border-line);
}
.sidebar-right #header_color_1 + label {
  background-color: #fff;
}
.sidebar-right #header_color_2 + label {
  background-color: #131722;
}
.sidebar-right #header_color_3 + label {
  background-color: #2d2b55;
}
.sidebar-right #header_color_4 + label {
  background-color: #30243d;
}
.sidebar-right #header_color_5 + label {
  background-color: #193549;
}
.sidebar-right #header_color_1 + label {
  border: 1px solid var(--border-line);
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

[data-bodybg='color_2'] {
  background-color: #131722 !important;
  color: #7d8db7 !important;
}
[data-bodybg='color_2'] h1,
[data-bodybg='color_2'] h2,
[data-bodybg='color_2'] h3,
[data-bodybg='color_2'] h4,
[data-bodybg='color_2'] h5,
[data-bodybg='color_2'] h6,
[data-bodybg='color_2'] .h1,
[data-bodybg='color_2'] .h2,
[data-bodybg='color_2'] .h3,
[data-bodybg='color_2'] .h4,
[data-bodybg='color_2'] .h5,
[data-bodybg='color_2'] .h6 {
  color: #e0e3ed;
}
[data-bodybg='color_2'] .sidebar,
[data-bodybg='color_2'] .header {
  background: #181e2c;
}
[data-bodybg='color_2'] .card {
  background: #181e2c;
  border: 1px solid #252d43;
}
[data-bodybg='color_2'] .card-header {
  background: #181e2c;
  border-color: #11151f;
}
[data-bodybg='color_2'] .card .card-body {
  background: #181e2c;
}
[data-bodybg='color_2'] .group-2JyOhh7Z {
  background-color: #181e2c !important;
}

[data-bodybg='color_3'] {
  background-color: #2d2b55 !important;
  color: #adabd4 !important;
}
[data-bodybg='color_3'] h1,
[data-bodybg='color_3'] h2,
[data-bodybg='color_3'] h3,
[data-bodybg='color_3'] h4,
[data-bodybg='color_3'] h5,
[data-bodybg='color_3'] h6,
[data-bodybg='color_3'] .h1,
[data-bodybg='color_3'] .h2,
[data-bodybg='color_3'] .h3,
[data-bodybg='color_3'] .h4,
[data-bodybg='color_3'] .h5,
[data-bodybg='color_3'] .h6 {
  color: white;
}
[data-bodybg='color_3'] .sidebar,
[data-bodybg='color_3'] .header {
  background: #32305f;
}
[data-bodybg='color_3'] .card {
  background: #32305f;
  border: 1px solid #3f3c77;
}
[data-bodybg='color_3'] .card-header {
  background: #32305f;
  border-color: #2b2952;
}
[data-bodybg='color_3'] .card .card-body {
  background: #32305f;
}
[data-bodybg='color_3'] .group-2JyOhh7Z {
  background-color: #32305f !important;
}

[data-bodybg='color_4'] {
  background-color: #30243d !important;
  color: #af9cc4 !important;
}
[data-bodybg='color_4'] h1,
[data-bodybg='color_4'] h2,
[data-bodybg='color_4'] h3,
[data-bodybg='color_4'] h4,
[data-bodybg='color_4'] h5,
[data-bodybg='color_4'] h6,
[data-bodybg='color_4'] .h1,
[data-bodybg='color_4'] .h2,
[data-bodybg='color_4'] .h3,
[data-bodybg='color_4'] .h4,
[data-bodybg='color_4'] .h5,
[data-bodybg='color_4'] .h6 {
  color: #fcfcfd;
}
[data-bodybg='color_4'] .sidebar,
[data-bodybg='color_4'] .header {
  background: #382a47;
}
[data-bodybg='color_4'] .card {
  background: #382a47;
  border: 1px solid #49375d;
}
[data-bodybg='color_4'] .card-header {
  background: #382a47;
  border-color: #2d223a;
}
[data-bodybg='color_4'] .card .card-body {
  background: #382a47;
}
[data-bodybg='color_4'] .group-2JyOhh7Z {
  background-color: #382a47 !important;
}

[data-bodybg='color_5'] {
  background-color: #193549 !important;
  color: #8ab7d7 !important;
}
[data-bodybg='color_5'] h1,
[data-bodybg='color_5'] h2,
[data-bodybg='color_5'] h3,
[data-bodybg='color_5'] h4,
[data-bodybg='color_5'] h5,
[data-bodybg='color_5'] h6,
[data-bodybg='color_5'] .h1,
[data-bodybg='color_5'] .h2,
[data-bodybg='color_5'] .h3,
[data-bodybg='color_5'] .h4,
[data-bodybg='color_5'] .h5,
[data-bodybg='color_5'] .h6 {
  color: #fcfdfe;
}
[data-bodybg='color_5'] .sidebar,
[data-bodybg='color_5'] .header {
  background: #1d3d54;
}
[data-bodybg='color_5'] .card {
  background: #1d3d54;
  border: 1px solid #26516f;
}
[data-bodybg='color_5'] .card-header {
  background: #1d3d54;
  border-color: #183245;
}
[data-bodybg='color_5'] .card .card-body {
  background: #1d3d54;
}
[data-bodybg='color_5'] .group-2JyOhh7Z {
  background-color: #1d3d54 !important;
}

[data-primary-color='color_2'] .btn-primary {
  background-color: #ff0096;
  border-color: #ff0096;
}
[data-primary-color='color_2'] .btn-primary:hover,
[data-primary-color='color_2'] .btn-primary:focus,
[data-primary-color='color_2'] .btn-primary:active,
[data-primary-color='color_2'] .btn-primary.active {
  background-color: #cc0078;
  border-color: #cc0078;
  box-shadow: none;
}
[data-primary-color='color_2'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_2'] .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #cc0078;
  border-color: #cc0078;
  box-shadow: none;
}

[data-primary-color='color_2'] .btn-outline-primary {
  background-color: transparent;
  color: #ff0096;
  border-color: #ff0096;
}
[data-primary-color='color_2'] .btn-outline-primary:hover,
[data-primary-color='color_2'] .btn-outline-primary:focus,
[data-primary-color='color_2'] .btn-outline-primary:active,
[data-primary-color='color_2'] .btn-outline-primary.active {
  background-color: #cc0078;
  border-color: #cc0078;
  box-shadow: none;
  color: #fff;
}
[data-primary-color='color_2'] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_2'] .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #cc0078;
  border-color: #cc0078;
  box-shadow: none;
  color: #fff;
}

[data-primary-color='color_2'] .sidebar {
  background: #e60087;
}

[data-primary-color='color_2'] i.la,
[data-primary-color='color_2'] i.fa,
[data-primary-color='color_2'] .text-primary {
  color: #ff0096 !important;
}

[data-primary-color='color_2'] .bg-primary {
  background-color: #ff0096 !important;
}

[data-primary-color='color_2'] .owl-next,
[data-primary-color='color_2'] .owl-prev {
  background-color: #cc0078 !important;
}
[data-primary-color='color_2'] .owl-next i,
[data-primary-color='color_2'] .owl-prev i {
  color: #fff !important;
}

[data-primary-color='color_2'] .intro-form button {
  background: #ff0096;
  color: #fff !important;
}
[data-primary-color='color_2'] .intro-form button i {
  color: #fff !important;
}

[data-primary-color='color_2'] .intro-form-exchange .btn i {
  color: #fff !important;
}

[data-primary-color='color_2'] .product-feature-box i {
  color: #fff !important;
}

[data-primary-color='color_2'] .trust-content {
  border-color: #ff0096;
}

[data-primary-color='color_2'] .footer a {
  color: #ff0096 !important;
}

[data-primary-color='color_2'] .sort-view .search-timeline a:hover,
[data-primary-color='color_2'] .sort-view .search-timeline a:focus,
[data-primary-color='color_2'] .sort-view .search-timeline a:active,
[data-primary-color='color_2'] .sort-view .search-timeline a.active {
  color: #ff0096;
}

[data-primary-color='color_2'] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff;
}

[data-primary-color='color_2'] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color='color_2'] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color='color_2'] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color='color_2'] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #ff0096;
}

[data-primary-color='color_2'] .badge {
  background: #ff0096 !important;
  color: #fff !important;
}

[data-primary-color='color_2'] .intro-video-play::after {
  background: #ff0096;
}

[data-primary-color='color_2'] .menu ul li a,
[data-primary-color='color_2'] .menu ul li a i,
[data-primary-color='color_2'] .menu ul li a.active i {
  color: #fff !important;
}

[data-primary-color='color_2'] .page-title .page-title-content p {
  color: #ff0096;
}

[data-primary-color='color_2'] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color='color_2'] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color='color_2'] .profile_chart.card .card-header .duration-option a.active {
  background: #ff0096;
}

[data-primary-color='color_2'] .profile_log .user .thumb {
  background: #ff0096;
  color: #fff !important;
}
[data-primary-color='color_2'] .profile_log .user .thumb i {
  color: #fff !important;
}

[data-primary-color='color_2'] .trade-app-content .card-body a {
  color: #ff0096;
}

[data-primary-color='color_2'] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #ff0096;
}

[data-primary-color='color_2'] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #ff0096;
  color: white;
}

[data-primary-color='color_2'] .social-icons a {
  background: #ff0096;
  color: white !important;
}
[data-primary-color='color_2'] .social-icons a i {
  color: white !important;
}

[data-primary-color='color_2'] .settings_menu ul li a {
  color: #ff0096;
}

[data-primary-color='color_2'] .toggle-checkbox:checked + .toggle-switch {
  background: #ff0096;
}

[data-primary-color='color_2'] .file-upload-wrapper:before {
  background: #ff0096;
}

[data-primary-color='color_2'] .sidebar-right .sidebar-right-trigger {
  background-color: #ff0096 !important;
  color: #fff !important;
}
[data-primary-color='color_2'] .sidebar-right .sidebar-right-trigger i {
  color: #fff !important;
}

[data-primary-color='color_2'] .form-control:focus,
[data-primary-color='color_2'] .form-control:active,
[data-primary-color='color_2'] .form-control.active {
  border-color: #ff0096;
}

[data-primary-color='color_3'] .btn-primary {
  background-color: #002eff;
  border-color: #002eff;
}
[data-primary-color='color_3'] .btn-primary:hover,
[data-primary-color='color_3'] .btn-primary:focus,
[data-primary-color='color_3'] .btn-primary:active,
[data-primary-color='color_3'] .btn-primary.active {
  background-color: #0025cc;
  border-color: #0025cc;
  box-shadow: none;
}
[data-primary-color='color_3'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_3'] .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #0025cc;
  border-color: #0025cc;
  box-shadow: none;
}

[data-primary-color='color_3'] .btn-outline-primary {
  background-color: transparent;
  color: #002eff;
  border-color: #002eff;
}
[data-primary-color='color_3'] .btn-outline-primary:hover,
[data-primary-color='color_3'] .btn-outline-primary:focus,
[data-primary-color='color_3'] .btn-outline-primary:active,
[data-primary-color='color_3'] .btn-outline-primary.active {
  background-color: #0025cc;
  border-color: #0025cc;
  box-shadow: none;
  color: #fff;
}
[data-primary-color='color_3'] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_3'] .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #0025cc;
  border-color: #0025cc;
  box-shadow: none;
  color: #fff;
}

[data-primary-color='color_3'] .sidebar {
  background: #0029e6;
}

[data-primary-color='color_3'] i.la,
[data-primary-color='color_3'] i.fa,
[data-primary-color='color_3'] .text-primary {
  color: #002eff !important;
}

[data-primary-color='color_3'] .bg-primary {
  background-color: #002eff !important;
}

[data-primary-color='color_3'] .owl-next,
[data-primary-color='color_3'] .owl-prev {
  background-color: #0025cc !important;
}
[data-primary-color='color_3'] .owl-next i,
[data-primary-color='color_3'] .owl-prev i {
  color: #fff !important;
}

[data-primary-color='color_3'] .intro-form button {
  background: #002eff;
  color: #fff !important;
}
[data-primary-color='color_3'] .intro-form button i {
  color: #fff !important;
}

[data-primary-color='color_3'] .intro-form-exchange .btn i {
  color: #fff !important;
}

[data-primary-color='color_3'] .product-feature-box i {
  color: #fff !important;
}

[data-primary-color='color_3'] .trust-content {
  border-color: #002eff;
}

[data-primary-color='color_3'] .footer a {
  color: #002eff !important;
}

[data-primary-color='color_3'] .sort-view .search-timeline a:hover,
[data-primary-color='color_3'] .sort-view .search-timeline a:focus,
[data-primary-color='color_3'] .sort-view .search-timeline a:active,
[data-primary-color='color_3'] .sort-view .search-timeline a.active {
  color: #002eff;
}

[data-primary-color='color_3'] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff;
}

[data-primary-color='color_3'] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color='color_3'] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color='color_3'] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color='color_3'] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #002eff;
}

[data-primary-color='color_3'] .badge {
  background: #002eff !important;
  color: #fff !important;
}

[data-primary-color='color_3'] .intro-video-play::after {
  background: #002eff;
}

[data-primary-color='color_3'] .menu ul li a,
[data-primary-color='color_3'] .menu ul li a i,
[data-primary-color='color_3'] .menu ul li a.active i {
  color: #fff !important;
}

[data-primary-color='color_3'] .page-title .page-title-content p {
  color: #002eff;
}

[data-primary-color='color_3'] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color='color_3'] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color='color_3'] .profile_chart.card .card-header .duration-option a.active {
  background: #002eff;
}

[data-primary-color='color_3'] .profile_log .user .thumb {
  background: #002eff;
  color: #fff !important;
}
[data-primary-color='color_3'] .profile_log .user .thumb i {
  color: #fff !important;
}

[data-primary-color='color_3'] .trade-app-content .card-body a {
  color: #002eff;
}

[data-primary-color='color_3'] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #002eff;
}

[data-primary-color='color_3'] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #002eff;
  color: #fff;
}

[data-primary-color='color_3'] .social-icons a {
  background: #002eff;
  color: white !important;
}
[data-primary-color='color_3'] .social-icons a i {
  color: white !important;
}

[data-primary-color='color_3'] .settings_menu ul li a {
  color: #002eff;
}

[data-primary-color='color_3'] .toggle-checkbox:checked + .toggle-switch {
  background: #002eff;
}

[data-primary-color='color_3'] .file-upload-wrapper:before {
  background: #002eff;
}

[data-primary-color='color_3'] .sidebar-right .sidebar-right-trigger {
  background-color: #002eff !important;
  color: #fff !important;
}
[data-primary-color='color_3'] .sidebar-right .sidebar-right-trigger i {
  color: #fff !important;
}

[data-primary-color='color_3'] .form-control:focus,
[data-primary-color='color_3'] .form-control:active,
[data-primary-color='color_3'] .form-control.active {
  border-color: #002eff;
}

[data-primary-color='color_4'] .btn-primary {
  background-color: #ff2f00;
  border-color: #ff2f00;
}
[data-primary-color='color_4'] .btn-primary:hover,
[data-primary-color='color_4'] .btn-primary:focus,
[data-primary-color='color_4'] .btn-primary:active,
[data-primary-color='color_4'] .btn-primary.active {
  background-color: #cc2600;
  border-color: #cc2600;
  box-shadow: none;
}
[data-primary-color='color_4'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_4'] .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #cc2600;
  border-color: #cc2600;
  box-shadow: none;
}

[data-primary-color='color_4'] .btn-outline-primary {
  background-color: transparent;
  color: #ff2f00;
  border-color: #ff2f00;
}
[data-primary-color='color_4'] .btn-outline-primary:hover,
[data-primary-color='color_4'] .btn-outline-primary:focus,
[data-primary-color='color_4'] .btn-outline-primary:active,
[data-primary-color='color_4'] .btn-outline-primary.active {
  background-color: #cc2600;
  border-color: #cc2600;
  box-shadow: none;
  color: #fff;
}
[data-primary-color='color_4'] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_4'] .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #cc2600;
  border-color: #cc2600;
  box-shadow: none;
  color: #fff;
}

[data-primary-color='color_4'] .sidebar {
  background: #e62a00;
}

[data-primary-color='color_4'] i.la,
[data-primary-color='color_4'] i.fa,
[data-primary-color='color_4'] .text-primary {
  color: #ff2f00 !important;
}

[data-primary-color='color_4'] .bg-primary {
  background-color: #ff2f00 !important;
}

[data-primary-color='color_4'] .owl-next,
[data-primary-color='color_4'] .owl-prev {
  background-color: #cc2600 !important;
}
[data-primary-color='color_4'] .owl-next i,
[data-primary-color='color_4'] .owl-prev i {
  color: #fff !important;
}

[data-primary-color='color_4'] .intro-form button {
  background: #ff2f00;
  color: #fff !important;
}
[data-primary-color='color_4'] .intro-form button i {
  color: #fff !important;
}

[data-primary-color='color_4'] .intro-form-exchange .btn i {
  color: #fff !important;
}

[data-primary-color='color_4'] .product-feature-box i {
  color: #fff !important;
}

[data-primary-color='color_4'] .trust-content {
  border-color: #ff2f00;
}

[data-primary-color='color_4'] .footer a {
  color: #ff2f00 !important;
}

[data-primary-color='color_4'] .sort-view .search-timeline a:hover,
[data-primary-color='color_4'] .sort-view .search-timeline a:focus,
[data-primary-color='color_4'] .sort-view .search-timeline a:active,
[data-primary-color='color_4'] .sort-view .search-timeline a.active {
  color: #ff2f00;
}

[data-primary-color='color_4'] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff;
}

[data-primary-color='color_4'] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color='color_4'] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color='color_4'] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color='color_4'] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #ff2f00;
}

[data-primary-color='color_4'] .badge {
  background: #ff2f00 !important;
  color: #fff !important;
}

[data-primary-color='color_4'] .intro-video-play::after {
  background: #ff2f00;
}

[data-primary-color='color_4'] .menu ul li a,
[data-primary-color='color_4'] .menu ul li a i,
[data-primary-color='color_4'] .menu ul li a.active i {
  color: #fff !important;
}

[data-primary-color='color_4'] .page-title .page-title-content p {
  color: #ff2f00;
}

[data-primary-color='color_4'] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color='color_4'] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color='color_4'] .profile_chart.card .card-header .duration-option a.active {
  background: #ff2f00;
}

[data-primary-color='color_4'] .profile_log .user .thumb {
  background: #ff2f00;
  color: #fff !important;
}
[data-primary-color='color_4'] .profile_log .user .thumb i {
  color: #fff !important;
}

[data-primary-color='color_4'] .trade-app-content .card-body a {
  color: #ff2f00;
}

[data-primary-color='color_4'] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #ff2f00;
}

[data-primary-color='color_4'] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #ff2f00;
  color: #fff;
}

[data-primary-color='color_4'] .social-icons a {
  background: #ff2f00;
  color: white !important;
}
[data-primary-color='color_4'] .social-icons a i {
  color: white !important;
}

[data-primary-color='color_4'] .settings_menu ul li a {
  color: #ff2f00;
}

[data-primary-color='color_4'] .toggle-checkbox:checked + .toggle-switch {
  background: #ff2f00;
}

[data-primary-color='color_4'] .file-upload-wrapper:before {
  background: #ff2f00;
}

[data-primary-color='color_4'] .sidebar-right .sidebar-right-trigger {
  background-color: #ff2f00 !important;
  color: #fff !important;
}
[data-primary-color='color_4'] .sidebar-right .sidebar-right-trigger i {
  color: #fff !important;
}

[data-primary-color='color_4'] .form-control:focus,
[data-primary-color='color_4'] .form-control:active,
[data-primary-color='color_4'] .form-control.active {
  border-color: #ff2f00;
}

[data-primary-color='color_5'] .btn-primary {
  background-color: #00ccd3;
  border-color: #00ccd3;
}
[data-primary-color='color_5'] .btn-primary:hover,
[data-primary-color='color_5'] .btn-primary:focus,
[data-primary-color='color_5'] .btn-primary:active,
[data-primary-color='color_5'] .btn-primary.active {
  background-color: #009ba0;
  border-color: #009ba0;
  box-shadow: none;
}
[data-primary-color='color_5'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_5'] .btn-primary:not(:disabled):not(.disabled).active {
  background-color: #009ba0;
  border-color: #009ba0;
  box-shadow: none;
}

[data-primary-color='color_5'] .btn-outline-primary {
  background-color: transparent;
  color: #00ccd3;
  border-color: #00ccd3;
}
[data-primary-color='color_5'] .btn-outline-primary:hover,
[data-primary-color='color_5'] .btn-outline-primary:focus,
[data-primary-color='color_5'] .btn-outline-primary:active,
[data-primary-color='color_5'] .btn-outline-primary.active {
  background-color: #009ba0;
  border-color: #009ba0;
  box-shadow: none;
  color: #fff;
}
[data-primary-color='color_5'] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary-color='color_5'] .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #009ba0;
  border-color: #009ba0;
  box-shadow: none;
  color: #fff;
}

[data-primary-color='color_5'] .sidebar {
  background: #00b3ba;
}

[data-primary-color='color_5'] i.la,
[data-primary-color='color_5'] i.fa,
[data-primary-color='color_5'] .text-primary {
  color: #00ccd3 !important;
}

[data-primary-color='color_5'] .bg-primary {
  background-color: #00ccd3 !important;
}

[data-primary-color='color_5'] .owl-next,
[data-primary-color='color_5'] .owl-prev {
  background-color: #009ba0 !important;
}
[data-primary-color='color_5'] .owl-next i,
[data-primary-color='color_5'] .owl-prev i {
  color: #fff !important;
}

[data-primary-color='color_5'] .intro-form button {
  background: #00ccd3;
  color: #fff !important;
}
[data-primary-color='color_5'] .intro-form button i {
  color: #fff !important;
}

[data-primary-color='color_5'] .intro-form-exchange .btn i {
  color: #fff !important;
}

[data-primary-color='color_5'] .product-feature-box i {
  color: #fff !important;
}

[data-primary-color='color_5'] .trust-content {
  border-color: #00ccd3;
}

[data-primary-color='color_5'] .footer a {
  color: #00ccd3 !important;
}

[data-primary-color='color_5'] .sort-view .search-timeline a:hover,
[data-primary-color='color_5'] .sort-view .search-timeline a:focus,
[data-primary-color='color_5'] .sort-view .search-timeline a:active,
[data-primary-color='color_5'] .sort-view .search-timeline a.active {
  color: #00ccd3;
}

[data-primary-color='color_5'] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff;
}

[data-primary-color='color_5'] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color='color_5'] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color='color_5'] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color='color_5'] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #00ccd3;
}

[data-primary-color='color_5'] .badge {
  background: #00ccd3 !important;
  color: #fff !important;
}

[data-primary-color='color_5'] .intro-video-play::after {
  background: #00ccd3;
}

[data-primary-color='color_5'] .menu ul li a,
[data-primary-color='color_5'] .menu ul li a i,
[data-primary-color='color_5'] .menu ul li a.active i {
  color: #fff !important;
}

[data-primary-color='color_5'] .page-title .page-title-content p {
  color: #00ccd3;
}

[data-primary-color='color_5'] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color='color_5'] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color='color_5'] .profile_chart.card .card-header .duration-option a.active {
  background: #00ccd3;
}

[data-primary-color='color_5'] .profile_log .user .thumb {
  background: #00ccd3;
  color: #fff !important;
}
[data-primary-color='color_5'] .profile_log .user .thumb i {
  color: #fff !important;
}

[data-primary-color='color_5'] .trade-app-content .card-body a {
  color: #00ccd3;
}

[data-primary-color='color_5'] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #00ccd3;
}

[data-primary-color='color_5'] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #00ccd3;
  color: white;
}

[data-primary-color='color_5'] .social-icons a {
  background: #00ccd3;
  color: white !important;
}
[data-primary-color='color_5'] .social-icons a i {
  color: white !important;
}

[data-primary-color='color_5'] .settings_menu ul li a {
  color: #00ccd3;
}

[data-primary-color='color_5'] .toggle-checkbox:checked + .toggle-switch {
  background: #00ccd3;
}

[data-primary-color='color_5'] .file-upload-wrapper:before {
  background: #00ccd3;
}

[data-primary-color='color_5'] .sidebar-right .sidebar-right-trigger {
  background-color: #00ccd3 !important;
  color: #fff !important;
}
[data-primary-color='color_5'] .sidebar-right .sidebar-right-trigger i {
  color: #fff !important;
}

[data-primary-color='color_5'] .form-control:focus,
[data-primary-color='color_5'] .form-control:active,
[data-primary-color='color_5'] .form-control.active {
  border-color: #00ccd3;
}

[data-font='opensans'] {
  font-family: 'Open Sans', sans-serif;
}

[data-font='nunito'] {
  font-family: 'Nunito', sans-serif;
}

.demo_img {
  text-align: center;
  margin-bottom: 60px;
}
.demo_img .img-wrap {
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.demo_img .img-wrap::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #1652f0;
  opacity: 0;
}
.demo_img .img-wrap.light {
  background: #fff;
}
.demo_img .img-wrap:hover::after,
.demo_img .img-wrap:focus::after {
  opacity: 0.5;
}
.demo_img img {
  border-radius: 5px;
}

.intro-demo_img {
  box-shadow: 0px 36px 48px rgba(93, 120, 255, 0.25);
  position: relative;
  overflow: hidden;
}
.intro-demo_img img {
  border-radius: 5px;
}
.intro-demo_img .dark-img {
  position: absolute;
  width: 90%;
  right: 0px;
  bottom: 0px;
}
.mobile-navi-bar {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: grey;
  color: white;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.mobile-trade {
  width: 100%;
  height: calc(100vh - 180px);
}
.mobile-trade .left {
  width: 40%;
  float: left;
}
.mobile-trade .right {
  width: 60%;
  float: right;
}

.mobile-orderbook {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/**
  DARK MODE
**/
:root[data-theme='light'] {
  --card-background: #ffffff;
  --base-background: #ffffff;
  --border-line: #d4d6dc;
  --default-text-color: #2b2b2b;
}

:root[data-theme='dark'] {
  --card-background: #131722;
  --base-background: #000000;
  --border-line: #434a59;
  --default-text-color: #ffffff;
}

.twitter-timeline {
  height: 300px !important;
}
